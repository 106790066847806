import React, { Fragment } from "react";
import classNames from "classnames";

const Card = ({ children, className = "" }) => {
  const defaultStyle = "border-t-[6px] border-primary-600 rounded-tl-xl rounded-tr-xl absolute top-0 right-[2px] left-[2px]";

  return (
    <Fragment>
      <div className="bg-white rounded-xl border-[1px] border-[#EAECF0] relative">
        <div className={classNames(defaultStyle.split(" "), className.split(" "))}></div>
        {children}
      </div>
    </Fragment>
  );
};

export default Card;
