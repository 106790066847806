import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  CollapsingCard,
  Label
} from "components";
import { InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import { useUserProfile } from "hooks/useUser";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { EVALUATION } from "services";
import ActivityDocument from "./activityDocument";
import AgreementTerms from "./agreementTerms";

const MonitoringDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const methods = useForm();

  const link = [
    {
      label: "Penugasan",
      path: "/monitoring",
    },
    {
      label: "Detail",
    },
  ];

  const { isLoading, data: programDetail } = useQuery({
    queryKey: ["program-detail", params.id],
    queryFn: async ({ signal, queryKey }) => {
      let response = await EVALUATION.getEvaluationDetail(params.id);
      return response.result;
    },
  });

  const { data: profile } = useUserProfile(["user-profile"]);

  const [approveModal, setApproveModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);

  const mutationUpdateStatus = useMutation((payload) => {
    return EVALUATION.putUpdateEvaluation(payload);
  });

  const onSubmitApproved = () => {
    const payload = {
      id: params.id,
      payload: {
        is_approve: true,
        reason: methods.watch("reason-approved"),
      },
    };

    mutationUpdateStatus.mutate(payload, {
      onSuccess: () => {
        setApproveModal(false);

        enqueueSnackbar({
          message: `Laporan telah disetujui`,
          variant: "success",
        });
      },
      onError: (error) => {
        console.log({ error });
        // setApproveModal(false);

        // enqueueSnackbar({
        //   message: `Laporan telah disetujui`,
        //   variant: "success",
        // });
      },
    });
  };

  const onSubmitDecline = () => {
    const payload = {
      id: params.id,
      payload: {
        is_approve: false,
        reject_reason: methods.watch("reason-decline"),
      },
    };

    mutationUpdateStatus.mutate(payload, {
      onSuccess: () => {
        setDeclineModal(false);

        enqueueSnackbar({
          message: `Berhasil tolak laporan. Laporan yang ditolak akan masuk list investigasi`,
          variant: "success",
        });
      },
      onError: (error) => {
        console.log({ error });
        // setApproveModal(false);

        // enqueueSnackbar({
        //   message: `Laporan telah disetujui`,
        //   variant: "success",
        // });
      },
    });
  };

  const renderModal = () => {
    return (
      <>
        <FormModal
          open={approveModal}
          onClose={setApproveModal}
          onSubmit={onSubmitApproved}
          width="w-1/3"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Setujui Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>
          </div>
        </FormModal>
        <FormModal
          open={declineModal}
          onClose={setDeclineModal}
          onSubmit={onSubmitDecline}
          width="w-1/3"
          btnLabelSubmit="Tolak"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Tolak Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <Label>Alasan Ditolak</Label>
              <InputForm
                type="textarea"
                className="w-full"
                name="reason-decline"
                placeholder="Masukkan alasan ditolak"
              />
            </div>
          </div>
        </FormModal>
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      {renderModal()}
      <div className="space-y-6 pb-12 mb-8 flex flex-col gap-4">
        <Breadcrumbs items={link} />

        {programDetail?.reject_reason != null ? (
          <Card className="!border-error-600">
            <CardHeader>
              <div className="flex items-center justify-between">
                <div>Alasan Penolakan</div>
              </div>
            </CardHeader>
            <CardBody>{programDetail?.reject_reason}</CardBody>
          </Card>
        ) : null}

        <CollapsingCard title="Dokumentasi Kegiatan">
          <ActivityDocument data={programDetail} />
        </CollapsingCard>
        {programDetail?.question_answer.length > 0 &&
          programDetail?.question_answer.map((list) => {
            return (
              <CollapsingCard
                title={`${list.i_section}. ${list.indicator_name}`}
              >
                <AgreementTerms data={list.result_answer} />
              </CollapsingCard>
            );
          })}
      </div>
      <div className="fixed bottom-0 left-[283px] right-[2%]  bg-white h-20 border border-gray-200 shadow p-3 rounded">
        <div className="flex justify-between items-center ">
          <div>
            <Button
              onClick={() => navigate("/monitoring")}
              className="w-32 border-gray-200 shadow-lg bg-white text-black hover:bg-gray-100 hover:border-gray-200"
            >
              Kembali
            </Button>
          </div>
          {(profile?.role === "PIC" || profile?.role === "KPL") && programDetail?.status == 'waiting_approval' && (
            <div className="space-x-5">
              <Button
                onClick={() => setDeclineModal(true)}
                className="w-32 border-gray-200 shadow-lg bg-white text-red-500 hover:bg-white hover:border-white hover:text-red-700"
              >
                Tolak
              </Button>
              <Button
                onClick={() => setApproveModal(true)}
                className="w-32 text-white shadow-lg bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
              >
                Disetujui
              </Button>
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default MonitoringDetail;
