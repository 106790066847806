import { Breadcrumbs, Button, Card, CardBody, CardHeader, CollapsingCard, Label } from "components";
import { CheckboxForm, CustomSelectForm, InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import { useProgramProgressDetail } from "hooks/useProgramProgress";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router";
import { PROGRESS_REPORT } from "services";
import ContributionChart from "../Chart/contribution-chart";

const ProgramProgressDetail = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const params = useParams();

  const { data } = useProgramProgressDetail(params.id);

  const [approveModal, setApproveModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);

  const mutationUpdateStatus = useMutation((payload) => {
    PROGRESS_REPORT.putUpdateStatusLaporan(payload);
  });

  const links = [
    {
      label: "Dana Program",
      path: "#",
    },
    {
      label: "Laporan Kemajuan",
      path: "/program",
    },
    {
      label: "Detail",
    },
  ];

  const onSubmitApproved = () => {
    const payload = {
      id: params.id,
      payload: {
        status: 1,
        reason: methods.watch("reason-approved"),
      },
    };

    mutationUpdateStatus.mutate(payload, {
      onSuccess: () => {
        setApproveModal(false);

        enqueueSnackbar({
          message: `Laporan telah disetujui`,
          variant: "success",
        });

        navigate("/program-progress");
      },
    });
  };

  const onSubmitDecline = () => {
    const payload = {
      id: params.id,
      payload: {
        status: 2,
        reason: methods.watch("reason-decline"),
      },
    };

    mutationUpdateStatus.mutate(payload, {
      onSuccess: () => {
        setDeclineModal(false);

        enqueueSnackbar({
          message: `Berhasil tolak laporan. Laporan yang ditolak akan masuk list investigasi`,
          variant: "success",
        });

        navigate("/program-progress");
      },
    });
  };

  const renderModal = () => {
    return (
      <>
        <FormModal
          open={approveModal}
          onClose={setApproveModal}
          onSubmit={onSubmitApproved}
          width="w-1/3"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Setujui Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>
            <CheckboxForm label="Setujui Bersyarat" name="approve" />

            <div className="flex flex-col gap-1">
              <Label>Alasan Disetujui</Label>
              <InputForm
                type="textarea"
                className="w-full"
                name="reason-approved"
                placeholder="Masukkan alasan disetujui"
              />
            </div>
          </div>
        </FormModal>
        <FormModal
          open={declineModal}
          onClose={setDeclineModal}
          onSubmit={onSubmitDecline}
          width="w-1/3"
          btnLabelSubmit="Tolak"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Tolak Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <Label>Kategori Penolakan</Label>
              <CustomSelectForm className="w-full" name="cateogry-decline" />
            </div>

            <div className="flex flex-col gap-1">
              <Label>Alasan Ditolak</Label>
              <InputForm
                type="textarea"
                className="w-full"
                name="reason-decline"
                placeholder="Masukkan alasan ditolak"
              />
            </div>
          </div>
        </FormModal>
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-6 pb-12 mb-8">
        {renderModal()}
        <Breadcrumbs items={links} />

        {data?.result?.status === 2 ? (
          <Card className="!border-error-600">
            <CardHeader>
              <div className="flex items-center justify-between">
                <div>Alasan Penolakan</div>
              </div>
            </CardHeader>
            <CardBody>{data?.result?.rejected_reason}</CardBody>
          </Card>
        ) : null}

        <CollapsingCard title="Laporan Kemajuan">
          <div className="flex mb-5">
            <div className="flex flex-col flex-1">
              <p className="w-1/4 font-bold">Proyek:</p>
              <div className="w-full bg-gray-200 p-4 mt-2 rounded-md">
                {data?.result?.cfp?.proposal_title ?? "-"}
              </div>
            </div>
          </div>

          <div className="space-y-5">
            {data?.result?.output.map((data) => {
              return (
                <CollapsingCard
                  title={data?.name ?? "-"}
                  headerClassname="bg-green-100 text-green-600"
                >
                  <div className="space-y-10">
                    {data.indicator_list.length > 0
                      ? data.indicator_list.map((list) => {
                          return (
                            <div className="flex w-full p-5 gap-5 border rounded-lg">
                              <div className="flex w-3/4 flex-col">
                                <p className="w-full font-bold">Indikator:</p>
                                <p className="w-full">{list.name}</p>
                              </div>
                              <div className="flex w-1/4 flex-col">
                                <span className="font-semibold">
                                  Update Progress Capaian:
                                </span>
                                <ContributionChart data={list?.contribution} />
                              </div>
                            </div>
                          );
                        })
                        : null}
                  </div>
                </CollapsingCard>
              );
            })}
          </div>
        </CollapsingCard>
      </div>
      <div className="fixed bottom-0 left-[283px] right-[2%]  bg-white h-20 border border-gray-200 shadow p-3 rounded">
        <div className="flex justify-between items-center ">
          <div>
            <Button
              onClick={() => navigate("/program-progress")}
              className="w-32 border-gray-200 shadow-lg bg-white text-black hover:bg-gray-100 hover:border-gray-200"
            >
              Kembali
            </Button>
          </div>
          {data?.result?.status === 0 && (
            <div className="space-x-5">
              <Button
                onClick={() => setDeclineModal(true)}
                className="w-32 border-gray-200 shadow-lg bg-white text-red-500 hover:bg-white hover:border-white hover:text-red-700"
              >
                Tolak
              </Button>
              <Button
                onClick={() => setApproveModal(true)}
                className="w-32 shadow-lg bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
              >
                Disetujui
              </Button>
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default ProgramProgressDetail;
